<template>
  <div class="content">
    <Breadcrumb :data="navData" :is$t="true" />
    <Box ref="contentBox" v-loading="loading" class="pane" style="position:relative;">
      <div slot="header" class="pane-header">
        <h4 class="title">{{ detail.title | textFilter }}</h4>
        <p class="sub-title">
          <span>{{ $t('122cefe') }}：</span>
          <span style="margin-right: 40px">{{
            detail.releaseTime
          }}</span>
          <span>{{ $t('52c01f1') }}：</span>
          <span>{{ detail.source | textFilter }}</span>
        </p>
      </div>
      <div class="pane-body">
        <div class="ql-editor" v-html="detail.content"></div>
      </div>
      <div ref="downloadBox" :class="`download ${fixed?'fixed':''}`">
        <p>
          <span>
            附件
          </span>
          <a :href="detail.url" download class="download-text">
            <span>{{ detail.filename || '合同下载' }}</span>
            <img :src="downloadIcon" />
          </a>
        </p>
      </div>
      <div slot="footer" class="page">
        <div class="page-info" @click="jumpDetail(detail.previousPageId, 'prev')">
          <img :src="icon" class="icon icon-left" />
          <p class="page-title">{{ $t('fccf558') }}：{{ detail.previousPage }}</p>
        </div>
        <div class="page-info" @click="jumpDetail(detail.nextPageId, 'next')">
          <p class="page-title">{{ $t('93e1a9d') }}：{{ detail.nextPage }}</p>
          <img :src="icon" class="icon icon-right" />
        </div>
      </div>
    </Box>
  </div>
</template>

<script>
import Box from '@/components/Box'
import Breadcrumb from '@/components/Breadcrumb'
import API from '@/api/contract/service'
export default {
  name: 'ContractDetail',
  components: {
    Box,
    Breadcrumb,
  },
  data() {
    return {
      loading: true,
      size: 15,
      detail: {},
      fixed: false,
      navData: [
        { name: '574d006', path: '/service/' },
        { name: '合同模板', path: '/service/contract' }
      ],
      downloadIcon: require('@/assets/service/contract/download.png'),
      icon: require('@/assets/communication/icon-arrow.png')
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getDetail()
    })
  },
  created() {
    // this.getDetail()
    window.onscroll = this.handleScroll
  },
  beforeDestroy() {
    window.onscroll = undefined
  },
  methods: {
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const wh = window.innerHeight
      const content = this.$refs.contentBox.$el
      const bottomHeight = content.clientHeight + content.offsetTop
      this.fixed = wh - (bottomHeight - scrollTop) < -70
      // console.log(content.offsetHeight, content.offsetTop, this.$refs.contentBox, e, scrollTop)
      // debugger
    },
    getDetail() {
      const id = this.$route.query.id
      // const page = this.$route.query.page
      // const size = this.$route.query.size
      this.loading = true
      API.getDetail(id, { page: this.$route.query.page, size: this.size })
        .then((res) => {
          this.detail = res
          this.navData = [
            ...this.navData,
            {
              name: res.title,
              path: ''
            }
          ]
          setTimeout(this.handleScroll, 100)
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
    jumpDetail(id, type) {
      if (!id) return
      let page = Number(this.$route.query.page)
      const { firstOne, lastOne } = this.detail
      if (type === 'prev' && firstOne && page > 1) {
        // 如果是第二页的第一个，往前面翻页的话，url上面的page要减掉1
        page = page - 1
      } else if (lastOne && type === 'next') {
        page = page + 1
      }
      window.location = `/service/contract/detail?id=${id}&page=${page}`
      // this.$router.push({
      //   query: {
      //     id,
      //     page
      //   }
      // })
    }
  },
}
</script>

<style scoped lang="less">
.content {
  width: 1200px;
  margin: 0 auto;
}
.page {
  color: #333333FF;
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  height: 74px;
  line-height: 74px;
  border-top: 1px solid #EEEEEE;
  .page-title {
    cursor: pointer;
  }
  .icon {
    cursor: pointer;
    width: 8px;
    height: 14px;
    &-left {
      margin-right: 20px;
    }
    &-right {
      margin-left: 20px;
      transform: rotateY(180deg);
    }
  }
  &-info {
    display: flex;
    align-items: center;
    &:first-child {
      text-align: left;
    }
    &:last-child {
      justify-content: flex-end;
      text-align: right;
    }
  }
  &-title {
    /*width: 500px;*/
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.pane-header {
  padding: 30px;
  border-bottom: 1px solid #eee;

  .title {
    font-size: 20px;
    color: #333333;
    line-height: 30px;
    font-weight: 500;
    text-align: center;
    padding-bottom: 30px;
  }

  .sub-title {
    font-size: 13px;
    color: #999999;
    text-align: center;
  }
}

.pane-body {
  font-size: 14px;
  color: #999999;
  line-height: 1.5;
  min-height: 10vh;
  //max-height: 60vh;
  //overflow-y: scroll;
  border-bottom: 1px dashed #00000017;
  padding-bottom: 50px;
  p {
    padding-bottom: 30px;
  }
  /deep/ img {
    max-width: 100%;
  }
}
.download {
  //position: fixed;
  //bottom: 0;
  width: 1140px;
  padding: 20px 0;
  background: white;
  position: absolute;
  bottom: 75px;
  border-top: 1px dashed #aaaaaa;
  &-text {
    color: #00A4FFFF;
    padding-left: 14px;
    img {
      width: 14px;
      height: 14px;
    }
  }
}
.download.fixed {
  position: fixed;
  bottom: 0px;
}
</style>
